import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import './assets/styles/styles.scss';
import logo from '../../../../static/img/logo.svg';
import logoSm from '../../../../static/img/logo_sm.png';
import googlePlay from '../../../../static/img/googlePlay.png';
import appleStore from '../../../../static/img/appleStore.png';
import { setCookie, getCookie } from '../../../../utils';

class MobileDisclamer extends PureComponent {
  state = {
    continueSerfing: !!getCookie('allow_mobile'),
  };


  static getMobileOperatingSystemLogo = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return (
        <a href="https://play.google.com/store/apps/details?id=com.sm_service.m_fond&hl=ru">
          <img className="disclamer-screen__logo-sm" alt="Морской фонд" src={logoSm} />
        </a>
      );
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return (
        <a href="https://itunes.apple.com/us/app/морской-фонд/id1442841278?l=ru&ls=1&mt=8">
          <img className="disclamer-screen__logo-sm" alt="Морской фонд" src={logoSm} />
        </a>
      );
    }

    return <img className="disclamer-screen__logo-sm" alt="Морской фонд" src={logoSm} />;
  };

  continueSerfing = () => {
    setCookie('allow_mobile', 'true', { expires: 3600000 * 2 });
    this.setState({ continueSerfing: true });
  };


  render() {
    const { continueSerfing } = this.state;
    if (continueSerfing) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="disclamer-screen">
        <img className="disclamer-screen__logo" alt="Морской фонд" src={logo} />
        <div className="disclamer-screen__wrapper">
          Скачайте мобильное приложение
        </div>
        { MobileDisclamer.getMobileOperatingSystemLogo() }
        <div role="presentation" className="disclamer-screen__continue" onClick={this.continueSerfing}>Продолжить в веб-версии</div>
        <div className="disclamer-screen__download">
          <a href="https://play.google.com/store/apps/details?id=com.sm_service.m_fond&hl=ru">
            <img className="disclamer-screen__download-logo" alt="Android" src={googlePlay} />
          </a>
          <div className="disclamer-screen__devider" />
          <a href="https://itunes.apple.com/us/app/морской-фонд/id1442841278?l=ru&ls=1&mt=8">
            <img className="disclamer-screen__download-logo" alt="Iphone" src={appleStore} />
          </a>
        </div>
      </div>
    );
  }
}

export default MobileDisclamer;
