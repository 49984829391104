import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../actions';
import './assets/styles/styles.scss';
import LoginForm from '../../components/LoginForm';
import logo from '../../../../static/img/logo.svg';
import Throbber from '../../../../commonComponents/Throbber';

const LoginContainer = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  }

  document.title = 'Личный кабинет пайщика';

  return (
    <div className="login-screen">
      <img className="login-screen__logo" alt="Морской фонд" src={logo} />
      <div className="login-screen__wrapper">
        <p className="login-screen__heading">Вход в личный кабинет</p>
        <LoginForm
          loginAction={props.appActions.login}
          authError={props.authError ? props.authError.message : null}
          fetching={props.fetching}
        />
        {props.fetching &&
          <Throbber className="login-screen__throbber" />
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.appReducer.isAuthenticated,
  authError: state.appReducer.authError,
  fetching: state.appReducer.fetching,
});
const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(appActions, dispatch),
});

const Login = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);

export default Login;

LoginContainer.propTypes = {
  appActions: PropTypes.objectOf(PropTypes.func).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  authError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  fetching: PropTypes.bool.isRequired,
};

LoginContainer.defaultProps = {
  authError: null,
};
