import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';
import logo from '../../static/img/logo.svg';

const SiteLogo = props => (
  <img
    className={cx('logo', props.className)}
    src={logo}
    alt="Морской фонд"
  />
);

export default SiteLogo;

SiteLogo.propTypes = {
  className: PropTypes.objectOf(PropTypes.string),
};

SiteLogo.defaultProps = {
  className: {},
};
